<template>
  <div class="faq">
    <img class="top" src="../assets/faq_main.png" />
    <div class="content">
      <div class="title">Frequently Asked Questions</div>
      <div class="list">
        <div v-for="(item, index) in value" :key="index" @click="click(index)">
          <span>> {{ item.title }}</span>
          <div :class="['val', { 'expanded': list.indexOf(index) > -1 }]">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      value: [
        {
          title: 'What is LoanNaira?',
          content: 'LoanNaira is a digital bank. We make it easy for you to apply for a loan and receive your loan into your bank account in minutes 24/7.',
        },
        {
          title: 'I have a complaint or feedback; how do I contact you?',
          content: 'Please send an email to support@LoanNaira.com or reach out through our social media platforms.',
        },
        {
          title: 'Where is LoanNaira located?',
          content: 'LoanNaira office is located in Lagos, Nigeria. See LoanNaira.ng/contact for more details',
        },
        {
          title: 'How can I get an instant loan?',
          content: 'You need an Android phone and a BVN. That\'s all! Download the LoanNaira app from the Google Play Store. Sign up with the phone number linked to your BVN, answer a few questions and confirm your identity to receive a loan offer. If you accept it, you will receive your loan instantly in your preferred bank account!',
        },
        {
          title: 'How much can I borrow and for how long?',
          content: 'LoanNaira offers loans from loans of up to 1 million Nairas for a period of up to 18 months. The better your lending history and positive behavior, the more you can access and the longer the period!',
        },
        {
          title: 'What makes LoanNaira different from other lenders?',
          content: 'Speed of delivery – You can get a loan instantly in 5 minutes, from anywhere in India Flexibility – You can repay on or before your due date No hidden charges – Our interest and fees charges are all-inclusive, ensuring transparency The customer is King – Our team is trained to treat you with the utmost respect at all times',
        },
        {
          title: 'Do I need documents or a collateral?',
          content: 'No document, no collateral! You will only need to share your BVN details during the loan application and connect your card or bank account to authorize us to debit your account for the repayment.',
        },
        {
          title: 'Why was my loan application rejected?',
          content: 'Apologies on that, you can apply again in a few days! It might be for different reasons: - Not enough information found on the phone: You should increase your phone usage (Calls/SMS) to be eligible. - We decided not to give you a credit at this time after analyzing your profile. Keep using your bank account with healthy revenues to get a loan soon.',
        },
        {
          title: 'How can I repay?',
          content: 'Click on the "Repay" button in the app to pay via card or bank transfer',
        },
        {
          title: 'How do I check my account balance and payment date?',
          content: 'Please log in in the app, you will find your customized dashboard that contains your credit status and/or all the details concerning your current balance.',
        },
        {
          title: 'Can I get another loan immediately?',
          content: 'As soon as your previous loan repayment is confirmed, you can reapply immediately and get another loan in seconds!',
        },
        {
          title: 'How does auto-debit work?',
          content: '- Auto-debit allows you to repay your LoanNaira loan easily without taking care of it! - Your bank will ask you to authorize LoanNaira to debit your account at the repayment date.',
        },
        {
          title: 'How do I pay via card?',
          content: 'Select the “Debit/Credit Card” option when repaying your loan and enter your card details. If you don’t have a card, please contact your bank.',
        },
        {
          title: 'What happens if I don\'t pay back?',
          content: 'We are humans and understand that unexpected and unforeseen situations may occur. However, we do not reschedule payment dates, you should take a loan only if you have the capacity to repay it to avoid: - Late fees - Suspension of your LoanNaira account - Report to national credit bureaus as required by law (CRC, First Central)',
        },
        {
          title: 'Can I extend my loan duration?',
          content: 'Yes! Please go to "Manage loan" in the app to see extension offers (if any)',
        },
        {
          title: 'Can I trust LoanNaira with my identity and bank account details?',
          content: 'LoanNaira is a Nigerian company with headquarters in Europe. We comply with European data security standards. LoanNaira will never sell the information you shared to third parties.',
        },
        {
          title: 'Why do you request permissions on my phone when installing the LoanNaira app?',
          content: 'LoanNaira collect and processes securely data from your phone (SMS, device details, location, phonebook) to make a lending decision. This data is necessary for us to approve your loan in real-time without asking you for cumbersome paperwork.',
        },
        {
          title: 'What happens if I lose my phone?',
          content: 'Send an email to support@LoanNaira.com to get the details of your loan and your repayment date. You can also sign in from another phone using your phone number and password',
        },
        {
          title: 'What are your Terms of Use and Privacy Policy?',
          content: 'Go to our terms of use and privacy policy pages (links in the footer of LoanNaira.ng)',
        },
      ]
    }
  },
  methods: {
    click(val) {
      let index = this.list.indexOf(val)
      if (index == -1) {
        this.list.push(val)
      } else {
        this.list.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.faq {
  .top {
    width: 100%;
    display: block;
  }
  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: left;
    .title {
      margin-top: 28px;
      margin-bottom: 30px;
      color: #162F0E;
      font-size: 24px;
      font-weight: bold;
    }
    .list {
      margin-left: 8px;
      font-size: 18px;
      font-weight: bold;
      > div {
        margin-bottom: 24px;
        padding: 25px 32px 25px 40px;
        border: 2px solid #f5f5f8;
        border-radius: 10px;
        cursor: pointer;
      }
      .val {
        overflow: hidden;
        max-height: 0px;
        transition: padding .8s ease,max-height .4s ease;
        padding-top: 0px;
        font-size: 16px;
        line-height: 30px;
        font-weight: initial;
        color: #444647;
        margin-left: 15px;
        margin-top: 0px;
        &.expanded {
          max-height: 530px;
          padding-top: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>